<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="HEA_STRESS_CHECK_CLASS_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="heaStressCheckClassCd"
            label="평가구분"
            v-model="searchParam.heaStressCheckClassCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <c-table
      ref="table"
      title="설문항목 목록"
      tableId="table"
      :columns="grid.columns"
      :data="grid.data"
      :checkClickFlag="false"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline>
          <c-btn v-if="editable" label="LBLADD" icon="add" @btnClicked="add" />
          <c-btn
            v-if="editable" 
            :isSubmit="isSave"
            :url="saveUrl"
            :param="grid.data"
            mappingType="PUT"
            label="LBLSAVE"
            icon="save"
            @beforeAction="save"
            @btnCallback="saveCallback" />
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>
<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "jobStressItem",
    data() {
    return {
      searchParam: {
        heaStressCheckClassCd: null,
        useFlag: 'Y'
      },
      grid: {
        columns: [
          {
            required: true,
            name: 'heaStressCheckClassCd',
            field: 'heaStressCheckClassCd',
            label: '평가구분',
            align: 'center',
            style: 'width:100px',
            sortable: false,
            type: 'select',
            itemText: 'codeName',
            itemValue: 'code',
            codeGroupCd: 'HEA_STRESS_CHECK_CLASS_CD',
          },
          {
            required: true,
            name: 'jobStressItemName',
            field: 'jobStressItemName',
            label: 'LBLITEMS',
            align: 'left',
            style: 'width:500px',
            sortable: false,
            type: 'text'
          },
          {
            name: 'score',
            field: 'score',
            label: '점수',
            child: [
              {
                name: 'val1Score',
                field: 'val1Score',
                label: '전혀<br/>그렇지<br/>않다',
                align: 'right',
                style: 'width:100px',
                type: 'number',
                sortable: false,
                maxlength: 2
              },
              {
                name: 'val2Score',
                field: 'val2Score',
                label: '그렇지<br/>않다',
                align: 'right',
                style: 'width:100px',
                type: 'number',
                sortable: false,
                maxlength: 1
              },
              {
                name: 'val3Score',
                field: 'val3Score',
                label: '그렇다',
                align: 'right',
                style: 'width:100px',
                type: 'number',
                sortable: false,
                maxlength: 1
              },
              {
                name: 'val4Score',
                field: 'val4Score',
                label: '매우<br/>그렇다',
                align: 'right',
                style: 'width:100px',
                type: 'number',
                sortable: false,
                maxlength: 1
              },
            ]
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            sortable: true,
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'LBLSORTORDER',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            type: 'number',
          },
        ],
        data: [],
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      isSave: false,
      listUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.hea.jobStress.base.item.list.url
      this.saveUrl = transactionConfig.hea.jobStress.base.item.save.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */ 
    add() {
      if (!this.grid.data) this.grid.data = [];
      this.grid.data.splice(0, 0, {
        heaJobStressItemId: uid(),  // 직무스트레스 항목 일련번호
        heaStressCheckClassCd: null,  // 직무스트레스 평가항목 구분
        jobStressItemName: '',  // 직무스트레스 결과항목명
        val1Score: null,  // (점수)전혀 그렇지 않다
        val2Score: null,  // (점수)그렇지 않다
        val3Score: null,  // (점수)그렇다
        val4Score: null,  // (점수)매우 그렇다
        useFlag: 'Y',  // 사용여부
        sortOrder: null,  // 정렬순서
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      })
    },
    save() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {            
            this.isSave = !this.isSave
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  },
};
</script>
